

class Video {
    constructor(){
      this.vidList = null
      this.mainVideos = null
      this.watchedMain = []
      this.watchedUpskill = []
      this.trackerUpdates = {}
      this.fetchId = Math.random()
    }

    async getTrackVideos(type, fail = false){
      if(this.mainVideos === null) {
          try{
              const response = await fetch('/videos/file.txt?v=' + this.fetchId)
              if(!response.ok){      
                if(typeof fail == 'function') fail()
                return "response err"
              }
              const text = await response.text()
              this.vidList = text.split("\n")
              this.vidList.forEach((vid, i) => {
                  this.vidList[i] = vid.split(",")
              })
              this.mainVideos = this.vidList.filter(([a, b]) => b === "main"); //destructuring 
              if (type === "main"){ return this.mainVideos }
              if (type === "all") { return this.vidList }
          } catch(err) {
              console.log(err)
              return "catch err"
          }
          if(localStorage.watchedVideos){ this.watched = localStorage.watchedVideos}
      } else {
        if (type === "main"){ return this.mainVideos }
        if (type === "all") { return this.vidList }
      }

    }

  }
  
  export default new Video()