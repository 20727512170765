import {LitElement, html, css} from 'lit'
import {gotoRoute} from '../Router'
import App from '../App'

customElements.define('main-app-header', class AppHeader extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  static get properties(){
    return {
      pagename: {
        type: String
      }
    }
  }

  firstUpdated(){
      super.firstUpdated()
      document.addEventListener("mobile-trackscroll",(e) => this.scrollTracker(e)) 
  }

  scrollTracker(e){
      const mobileTrackdiv = this.shadowRoot.getElementById('tracker-holder')
      const scrollable =  mobileTrackdiv.scrollHeight > mobileTrackdiv.clientHeight;
      if(scrollable === true){ mobileTrackdiv.scrollTo(0, e.detail); }
  }


  //open side menu (clicking & scrolling is prevented)
  hamburgerClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    holder.style.width = "100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.5)"
    // this makes the menu cover the scroll bar
    let rightValue = window.innerWidth - App.rootEl.clientWidth
    appMenu.style.right = "-" + rightValue + 'px';
  }


  // Close side menu 
  closeMenuClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    appMenu.style.right = "-100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.0)"
    // wait half a second
        setTimeout(function () {
          holder.style.width = "0"
      }, 400);
  }

  // close menu on backdrop click
  backDropClick(e){
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    // if (e === backdrop){ this.closeMenuClick() }
    this.closeMenuClick()
  }
  

  menuClick(e){
    e.preventDefault()
    const currentPath = window.location.pathname
    const pathname = e.target.closest('a').pathname
    if(currentPath == pathname){return}
    else { 
      const mobile = App.isMobile()
      if (mobile == true){
        this.closeMenuClick()
        setTimeout(function () {
          gotoRoute(pathname)
        }, 300);
      }
      else { gotoRoute(pathname) }
    }
  }

  static styles = css`
    * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .app-header { 
        width: 100%; 
        position: relative; z-index: 99;
      }
      .flex-center { display: flex; align-items: center; justify-content: center; }
      #burger-div {
        background-color: rgb(0,0,0,0.4);
        backdrop-filter: blur(5px);
        border-bottom-left-radius: 500px;
        border-bottom-right-radius: 500px;
        padding: 0.7em;
        width: 70px;
        position: absolute;
        right: 20px;
        top: 0;
        cursor: pointer;
        text-align: left; 
      }
      #burger-div svg { height: 50px;   
         stroke: var(--icon-color); }

      .header-items { 
        width: 100vw;
        position: relative; 
        text-align: right; 
      }

      #drawer-backdrop-holder{
        position: absolute;
        top: 0;
        right: 0;
        width: 0;  
        height: 100vh;
        z-index: 10;
      }

      #drawer-backdrop{
        position: relative;
        width: 100%;  
        height: 100%;
        background-color: rgb(0,0,0,0.0);
        transition: background-color .5s ease-out;
      }

      #ps-drawer {
        background-color: var(--grey-color-700);
        position: absolute;
        z-index: 99;
        height: 100vh;
        width: 50vw;
        transition: .5s ease-out;
        right: -100%;
        top: 0;
      }

      #track-head {
        font-size: 1.5em;
        margin: 30px 1em 15px 1em;
        text-align: left;
        position: absolute;
        left: 0;
      }

      #nav-wrapper {
        position: relative;
        height: 90%;
        top: 0;
        right: 0;
        padding: 0 2vh 0 0 ;
        margin: 4em 0;
        width: 100%;
        background-color: var(--tracker-background);
      }

      .close-menu {
        width: 40px;
        padding: .3em;
        position: absolute;
        cursor: pointer;
        right: 45px;
        top: -50px;
        stroke: var(--icon-color);
      }

      .app-side-menu-items img { text-align: right; margin: auto; width: 70px; margin: 3vh 0; } 

      #tracker-holder {
        display: block;
        overflow-y: scroll;
        height: 100%;
        padding: 1vh 0 3vh 0  ;
        text-align: left;
        font-size: 2em;
      }

      #tracker {
        width: 100%;
      }


      .test {
        background-color: var(--tracker-background);
      }

      ::-webkit-scrollbar { width: 15px; }
      ::-webkit-scrollbar-track { background: var(--tracker-background);}
      ::-webkit-scrollbar-thumb { background: var(--grey-color-600); }
      ::-webkit-scrollbar-thumb:hover { background: var(--completed-text); }

      /* mobile styles */
      @media all and (max-width: 768px){   



      }

      @media all and (max-width: 620px){ 
        #ps-drawer { width: 80vw; } 
      }

      @media all and (max-width: 400px){ 
        #track-head {
          margin: 30px 1em 15px 1em;
          font-size: 1.2em;
        }
      }


      /* desktop styles */
      @media all and (min-width: 769px){  
        .app-header { display: none; }        
      }
  `

  render(){    
    return html`


    <header class="app-header">

      <div class="header-items">  
        <div id="burger-div" class="desktop-hide"  @click="${this.hamburgerClick}">    
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              height="100px" viewBox="0 0 117.333 117.667" enable-background="new 0 0 100 100" xml:space="preserve">
              <line fill="none" stroke-width="10" x1="58.5" y1="100.5" x2="58.5" y2="116"/>
              <line fill="none" stroke-width="10" x1="1" y1="58.5" x2="17" y2="58.5"/>
              <line fill="none" stroke-width="10" x1="58.5" y1="1" x2="58.5" y2="16.75"/>
              <line fill="none"  stroke-width="10" x1="99.25" y1="58.5" x2="116" y2="58.5"/>
              <circle fill="none" stroke-width="10" cx="58.5" cy="58.5" r="41.476"/>
          </svg>
        </div>

        <div id="drawer-backdrop-holder">
          <div id="drawer-backdrop" @click="${this.backDropClick}"></div>
        </div>
        <div id="ps-drawer">
          <h3 id="track-head">STUDY TRACKER</h3>

          <div id="nav-wrapper"> 

            <svg class="close-menu"  @click="${this.closeMenuClick}" alt="close" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="25px" viewBox="0 0 100 100" enable-background="new 0 0 34.25 33.875" xml:space="preserve">
                <line fill="none" stroke-width="15" stroke-linecap="round" x1="5.536" y1="5.79" x2="94.169" y2="94.422"/>
                <line fill="none" stroke-width="15" stroke-linecap="round" x1="5.388" y1="94.054" x2="94.096" y2="5.346"/>
            </svg>
            <div id="tracker-holder">
              <!-- <div id="tracker"></div> -->
              <tracker-component></tracker-component>
            </div>
          </div>
        </div>
        
      </div>



    </header>
  
    `
  }
})
