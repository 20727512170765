// console.log('app connected')
import App from './App.js'

//components
import './components/main-app-header'
import './components/main-app-footer'

import './components/wa-ads-mobile'
import './components/wa-ads-desktop'

import './components/tracker-holder' // needed for scroll bar styling 
import './components/tracker-component' 
import './components/c-spinner' 


//Styles
import './scss/styles.scss'


document.addEventListener('DOMContentLoaded', () => {
  App.init()
})

window.addEventListener("resize", () => {
  App.resizeListener()
})