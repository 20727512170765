import {LitElement, html, css} from 'lit'
import {anchorRoute} from '../Router'
import App from '../App'


customElements.define('main-app-footer', class AppFooter extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  firstUpdated(){
    super.firstUpdated()
    this.footer = document.querySelector('main-app-footer')
    App.setSiteWidth(this.footer)
  }

  static styles = css`
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      footer {
        height: 250px;
        margin-top: 30px;
        padding-top: 100px;
        padding-right: 15px;
        background: var(--footer-background);        
        color: white;
        font-size: 1.2em;
        display: flex; align-items: center; justify-content: center;
        flex-direction: column; 
        position: relative;
        
      }

      a {
        color: white;
        cursor: pointer;
      }

      nav {
        z-index: 50;
        font-weight: 400;
      }

      /* mobile styles */
      @media all and (max-width: 768px){   
        footer {
          height: 200px;
          margin-top: 30px;
          padding-top: 50px;
          font-size: 1em;
        }
      }
  
  `

  render(){    
    return html`

    <footer>
      <nav> Developed by 
        <a href="https://hashtag-webapp.com" target="_blank">
          #WEBAPP
        </a> 
      </nav>
    </footer>
  
    `
  }
})
