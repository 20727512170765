import {LitElement, html, css} from 'lit'
import {anchorRoute} from '../Router'
import App from '../App'

// not on home page

customElements.define('wa-ads-desktop', class WaAds extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  firstUpdated(){
    super.firstUpdated()
    this.footer = document.querySelector('main-app-footer')
    App.setSiteWidth(this.footer)
  }

  static styles = css`
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      aside {
        top: 20vh;
        position: sticky;
      }

      aside > div {
        right: 0;
        top: 100px;
        width: 15vw;
        height: auto;
        position: absolute;
      }

      p {
        right: 5px;
        top: 72px;
        position: absolute;
        font-size: .8em;
        opacity: 0.4;
      }

      .flip-container {
        width: 100%;
        height: 350px;
        background: var(--grey-100);
        position: relative;
      }



      .ad-holder {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 0.8s ease;
        animation: flip 6s infinite;
      }

      @keyframes flip {
        23% {transform: rotateY(0deg)}
        27%, 73% {transform: rotateY(180deg)}
        77% {transform: rotateY(0deg)}
      }

      .ad-front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        background: #ffc728;
        color: #000;
      }

      .ad-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        background: #fafafa;
        color: #333;
        text-align: center;
        transform: rotateY(180deg);
      }

      /* mobile styles */
      @media all and (max-width: 768px){   


      }
  
  `

  
      // a {
      //   position: absolute;
      //   top: 0;
      //   right: 0:
      //   border: 1px solid grey;
      //   background-color: yellow;
      //   height: auto;
      //   width: 11vw;
      // }


  render(){    
    return html`

    <aside>
      <p>Ad</p>

      <div>
        <div class="flip-container">
          <div class="ad-holder">
            <div class="ad-front">
              <a href="/" class="home-button primary">Front Ad Image here</a>
            </div>

            <div class="ad-back">
              <a href="/" class="home-button primary">Back Ad Image here</a>
            </div>
        
          </div>
        </div>
      </div>
    </aside>
  
    `
  }
})
