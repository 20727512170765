import App from '../App'
import {render, html} from 'lit/html.js'
import Video from '../functions/Video'
import Element from '../functions/Element'

class HomeView {
  init(){    
    // console.log('Home View.init')
    document.title = App.name   
    this.render() 
    this.getAllVideos()
  }


  doneClick(e){
    const watchDiv = e.target.closest('.done-click')
    const vidId = watchDiv.getAttribute('id')
    const watchImg = watchDiv.closest('.done-click').firstElementChild
    Video.trackerUpdates.id = vidId
    if(watchImg.classList.contains('ticked')){
      watchImg.classList.remove('ticked')
      Video.watchedMain = Video.watchedMain.filter(e => e !== vidId);
      const vidArrLength = Video.watchedMain.length
      if(vidArrLength === 0){
        localStorage.removeItem('watchedMainVideos')
      } else {
        localStorage.setItem('watchedMainVideos', Video.watchedMain)
      }
      Video.trackerUpdates.type = 'remove'
    } else {
      watchImg.classList.add('ticked')
      Video.watchedMain.push(vidId)
      localStorage.setItem('watchedMainVideos', Video.watchedMain.sort())
      Video.trackerUpdates.type = 'add'
    }
    document.dispatchEvent( new CustomEvent("update-tracker"));
    // update the 'continue/start' button
    const contButton = document.querySelector('.start-btn')
    if(Video.watchedMain.length > 0) {
      const vidId = Video.watchedMain.slice(-1)[0]
      contButton.href = '#v-' + vidId
      Element.replaceTextItem(contButton, "Continue")
    } else {
      contButton.href = '#about'
      Element.replaceTextItem(contButton, "Start")
    }
  }


  // Video.watchedUpskill = []
  watchClick(e){
    const watchDiv = e.target.closest('.watched-click')
    const vidId = watchDiv.getAttribute('id')
    const watchImg = watchDiv.closest('.watched-click').lastElementChild
    if(watchImg.classList.contains('ticked')){
      watchImg.classList.remove('ticked')
      Video.watchedUpskill = Video.watchedUpskill.filter(e => e !== vidId);
      if(Video.watchedUpskill.length === 0){
        localStorage.removeItem('watchedUpskillVideos')
      } else {
        localStorage.setItem('watchedUpskillVideos', Video.watchedUpskill)
      }
    } else {
      watchImg.classList.add('ticked')
      Video.watchedUpskill.push(vidId)
      localStorage.setItem('watchedUpskillVideos', Video.watchedUpskill)
    }
  }


  async getAllVideos(){
    try{
      await Video.getTrackVideos("all").then(() => {
        this.render()
        // add ticks to previous watched main videos:
        if(localStorage.watchedMainVideos){
          Video.watchedMain = localStorage.watchedMainVideos.split(",")
          Video.watchedMain.forEach(id => {
            const vidClick = document.getElementById(id).firstElementChild
            vidClick.classList.add('ticked')
          });
        }
        // add ticks to previous watched upskill videos:
        if(localStorage.watchedUpskillVideos){
          Video.watchedUpskill = localStorage.watchedUpskillVideos.split(",")
          Video.watchedUpskill.forEach(id => {
            const vidClick = document.getElementById(id).lastElementChild
            vidClick.classList.add('ticked')
          });
        }
      })
    }catch(err){
      console.log("Error getting videos. Please check your connection.")
      const newEmptyEl = Element.createNewElement('div', 'vid-error', 'p4')
      const elWithText = Element.appendTextNodes(newEmptyEl, 'Error getting videos. Please check your internet.')
      const spinner = document.getElementsByTagName('c-spinner')[0]
      spinner.replaceWith(elWithText)
    }
}

scrollToClick(e){
  e.preventDefault()
  const aTag = e.target.closest('a')
  const hrefParts = aTag.href.split('#')
  const vidId = hrefParts[1]
  document.getElementById(vidId).scrollIntoView();
}

  render(){
    const template = html`

        <main-app-header></main-app-header>
        <main id="home-body">
          
          <div class="hero">
            <img id="hero-img" src="../images/coding-pic.svg" alt="Man Coding">
            <div id="hero-title">
              <p>web</p><p>dev</p><p>pathway</p>
            </div>
            <div id="hero-words">
              ${localStorage.watchedMainVideos == null ? html`  
                <a href="#about" class="btn start-btn" @click="${this.scrollToClick}">Start</a>
              ` : html`                 
                <a href="#v-${localStorage.watchedMainVideos.split(",").slice(-1)[0]}" @click="${this.scrollToClick}" class="btn start-btn">Continue</a>
              `}
            </div>
          </div>

          <section id="about" class="clearfix">
              <p id="q-mark">?</p>
              <h1>ABOUT the PATHWAY</h1>
              <p id="p-about">
              This web development pathway offers you a structured learning path. It provides a step-by-step approach to learning, ensuring foundational concepts are understood before moving to advanced topics.  It helps in building a strong skill set including coding, problem-solving, and debugging which are valuable for developing websites.   
              Implement best practices and become a great coder!
              <!-- As you learn, you will build projects that showcase your skills to potential employers or clients.  This pathway guides you to stay updated with the latest technologies and best practices in the field.  After completing this pathway, many career opportunities will become available including roles such as; front-end, back-end, or full-stack developer positions.            </p> -->
          </section>

          <div id="side-holder">
            <!-- <wa-ads-desktop></wa-ads-desktop> -->
            <aside>
              <h3 id="track-head">STUDY TRACKER</h3>
              <tracker-holder></tracker-holder>
            </aside>
          
            <section id="videos">

              ${Video.vidList === null ? html`

                <br><br>
                <c-spinner spinnerSize="sp-sml"></c-spinner>

              ` : html`

                ${Video.vidList.map(([id, type, image, link, title, desc], i) => html `
                
                  ${type == "main" ? html`

                    <div id="v-${id}" class="main-vid">
                      <a href="${link}" class="main-href clearfix" target="_blank">
                          <img class="thumb" src="../images/vid-thumbs/${image}" alt="Video Thumbnail">
                          <div class="vid-info">
                            <h3 class="vid-title">${title}</h3>
                            <p class="vid-desc">${desc}</p>
                          </div>
                      </a> 
                      <div class="done-holder flex-center">
                        <div id="${id}" class="done-click flex-center" @click="${this.doneClick}">
                          <div class="done-btn flex-center "><img src="../images/tick.svg" alt="Tick"></div>
                          <div class="done-txt">done</div>
                        </div>
                      </div>
                    </div>

                  ` : html` 

                    <div class="up-vid">
                      <div class="up-info">
                        <p class="up-type">UPSKILL</p>
                          <a href="${link}" class="up-href" target="_blank">
                            <h4 class="up-title">${title}</h4>
                            <p class="up-desc">${desc}</p>
                          </a> 
                          <div id="${id}" class="watched-click flex-center" @click="${this.watchClick}">
                              <div class="done-txt ">watched</div>
                              <div class="done-btn flex-center"><div class="done-trick"></div><img src="../images/tick.svg" alt="Tick"></div>
                          </div>
                      </div>
                      <a class="up-thumb-box flex-center" href="${link}" target="_blank">
                        <img class="up-thumb" src="../images/vid-thumbs/${image}" alt="Video Thumbnail">
                      </a> 
                    </div>

                  ` }
                
                `)}

              ` }

            </section>
          </div> 

          <!-- <section>
            <wa-ads-mobile></wa-ads-mobile>
          </section> -->

          <section id="more-soon">
            <p>
              More videos coming soon...
            </p>
          </section>

        </main>
        <main-app-footer></main-app-footer>

    `

    render(template, App.rootEl)
  }
}

export default new HomeView()