import {LitElement, html, css} from 'lit'
import {gotoRoute} from '../Router'
import App from '../App'

customElements.define('tracker-holder', class TrackHolder extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  // static get properties(){
  //   return {
  //     // pagename: {
  //     //   type: String
  //     // }
  //   }
  // }

  firstUpdated(){
      super.firstUpdated()
      document.addEventListener("desktop-trackscroll",(e) => this.scrollTracker(e)) 

  }

  scrollTracker(e){
      const desktopTrackdiv = this.shadowRoot.getElementById('tracker-wrap')
      const scrollable =  desktopTrackdiv.scrollHeight > desktopTrackdiv.clientHeight;
      if(scrollable === true){ desktopTrackdiv.scrollTo(0, e.detail); }
  }


    // POSITION EQUATION: n * 55 - 50 
    //  .p1 { top: 5px; }

    // .track-btn - scale: 1.25; // animate size when moving 


  static styles = css`

    #tracker-wrap {
      position: relative;
      width: 250px;
      height: calc(100vh - 150px);
      overflow-y: auto;
      z-index: 10;
    }

    ::-webkit-scrollbar { width: 15px; }
    ::-webkit-scrollbar-track { background: var(--tracker-background);}
    ::-webkit-scrollbar-thumb { background: var(--grey-color-600); }
    ::-webkit-scrollbar-thumb:hover { background: var(--completed-text); }

  `

  render(){    
    return html`

      <div id="tracker-wrap">
        
        <tracker-component></tracker-component>
      </div>
  
    `
  }
})
