import Router from './Router'
import Element from './functions/Element'

class App {
  constructor(){
    this.name = "Web Dev Pathway"
    this.version = "1.0.0"
    this.apiBase = "https://ps.hashtag-webapp.com" 
    this.rootEl = document.getElementById("root")
  }

  // App.clientCode 
  // Work Version
  // http://localhost:3000
  // https://ps.hashtag-webapp.com' 

  init() { 
    Element.removeAllChildren(this.rootEl)
    // console.log('app init')
    Router.init()  
  } 

  resizeListener(){ 
    document.body.style.height = window.innerHeight + 'px';  
    const footer = document.querySelector('main-app-footer');
    this.setSiteWidth(footer)
  }


  isMobile(){
    let viewportWidth = window.innerWidth
    if(viewportWidth <= 768){ return true }
    return false
  }

  setSiteWidth(el){
    const width = this.rootEl.clientWidth
    el.style.width = width + "px"
  }

  
}

export default new App()