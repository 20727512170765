import {LitElement, html, css} from 'lit'
import {gotoRoute} from '../Router'
import App from '../App'
import Video from '../functions/Video'
import Element from '../functions/Element'

customElements.define('tracker-component', class Tracker extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  // static get properties(){
  //   return {
  //     pagename: {
  //       type: String
  //     }
  //   }
  // }

  firstUpdated(){
    super.firstUpdated()
    this.trackVideos = null
    this.tracker = this.shadowRoot.getElementById('track-vids-box')
    this.getTrackVideos()
    document.addEventListener("update-tracker",() => this.updateTracker()) 
  }


  updateTracker(){
    // console.log('run update tracker function')
    this.repositionButton(Video.trackerUpdates.type)
  }


  async getTrackVideos(){
      let watched = [];
      // console.log(localStorage.watchedMainVideos)
      if(localStorage.watchedMainVideos){
        watched = localStorage.watchedMainVideos.split(",")
      }
      try{
          await Video.getTrackVideos("main").then(() => {
              Video.mainVideos.forEach(([id, type, image, link, title, desc], i) => {
                const el = document.createElement('a')
                el.id = `t-${id}`;
                el.className = `t-vid p${i + 2}`;
                el.href = `#v-${id}`
                let doneClasses = "t-done-box";
                if(watched.length > 0 && watched.includes(id)){
                  doneClasses = "t-done-box t-ticked"
                }
                el.innerHTML = `
                  <div class="${doneClasses}">
                    <div class="t-target"></div>
                    <img src="../images/tick.svg" alt="Tick">
                  </div>
                  <p class="t-title">${title}</p>
                `
                el.addEventListener("click", this.scrollToClick.bind(this))
                this.tracker.append(el)
              })
              this.tracker.style.height = ((Video.mainVideos.length + 1)*55 + 50) + 'px';
              this.repositionButton('init')
          })
      }catch(err){
        console.log("Error getting videos")
        const newEmptyEl = Element.createNewElement('div', 'vid-error', 'p4')
        const elWithText = Element.appendTextNodes(newEmptyEl, 'Error getting videos. Please check your internet.')
        this.tracker.append(elWithText)
      }
  }



// can refactor for 'add' and 'remove' switch by passing in a detail object with the event listener
      // ... instead of using Video.trackerUpdates.id  & Video.trackerUpdates.type
  repositionButton(type){
      let move = true // allows the function to cancel when false
      let i = 0 //get number of array items to reposition the start button
      const nextButton = this.shadowRoot.getElementById('next-btn')
      let nextPosObject = this.getPosAndClass(nextButton.classList)
      const checkButtons = this.shadowRoot.querySelectorAll('.t-done-box')

      switch (type) {
          case 'init':
              // get number of consecutive ticked divs to move on init
              while (move === true && i < Video.mainVideos.length) {
                if(checkButtons[i].classList.contains('t-ticked')){
                  i++;
                } else {
                  move = false;
                }
              }
              if(i === 0){return}
              // get all number of divs before the button and move them above the button
              let d = 0
              while(d<i){        
                  this.swapPosClasses('p' + (d + 2), 'p' + (d + 1));
                  d++;
              }
              // move the button below the ticked divs
              nextButton.classList.replace('p1',  'p' + (i + 1))
              //updating the href link for the next lesson
              if(i < Video.mainVideos.length){
                nextButton.href = '#v-' + Video.mainVideos[i][0]
              } else {
                nextButton.href = '#v-' + Video.mainVideos[i-1][0]
              }
              //scrolling button into view for better UX
              if(i >= 5){
                  const mobile = App.isMobile()
                  if(mobile === true){
                      document.dispatchEvent( new CustomEvent("mobile-trackscroll", { 'detail': (i * 55) }));
                  } else {
                      document.dispatchEvent( new CustomEvent("desktop-trackscroll", { 'detail': (i * 55) }));
                  }
              }
              break;
          case 'add': {
              // add tick class from  Video.trackerUpdates.id
              const updateVid = this.shadowRoot.getElementById('t-' + Video.trackerUpdates.id);
              updateVid.firstElementChild.classList.add('t-ticked');
              // use nextPosObject to get the button in the next position
              let afterNext = this.shadowRoot.querySelector(".p" + (nextPosObject.number + 1));
              move = true;
              let i = 0;
              // while(move === true && i < 10){ // used this for testing
              while(move === true){
                  // continue until next div not ticket or null
                  if(afterNext ==  null){
                      // console.log('no more divs');
                      move = false;
                      return
                  } else {
                      if(afterNext.firstElementChild.classList.contains('t-ticked')){
                        // swap classes here
                        nextButton.classList.replace(nextPosObject.class,"p" + (nextPosObject.number + 1));
                        afterNext.classList.replace("p" + (nextPosObject.number + 1), nextPosObject.class);
                        // reset new element data
                        nextPosObject = this.getPosAndClass(nextButton.classList);
                        afterNext = this.shadowRoot.querySelector(".p" + (nextPosObject.number + 1));
                        // i++; // to prevent browser from crashing while testing
                      } else {
                        move = false;
                      }
                  }
              }
              //update href to next video
              nextButton.href = '#v' + afterNext.getAttribute('id').slice(1)
              // animate nextButton 
              nextButton.classList.add('move-btn')
              setTimeout(() => {
                nextButton.classList.remove('move-btn')
              }, 400);
              
              break;
          }

          case 'remove': {
              // remove tick class from  Video.trackerUpdates.id
              const updateVid = this.shadowRoot.getElementById('t-' + Video.trackerUpdates.id)
              updateVid.firstElementChild.classList.remove('t-ticked')
              //check if the updated vid is positioned before the button 
              const vidPosObject = this.getPosAndClass(updateVid.classList)
              nextPosObject = this.getPosAndClass(nextButton.classList)
              // if so, move the buttons 
              if(vidPosObject.number < nextPosObject.number){
                // console.log('move next button')
                const m = nextPosObject.number - vidPosObject.number
                // console.log('move: ' + m)

                // ANIMATE THE NEXT BUTTON "SCALE"
                nextButton.classList.add('move-btn')
                setTimeout(() => {
                  nextButton.classList.remove('move-btn')
                }, 400);

                let n = 0; // for number of moves
                while(n < m){
                  // console.log('swapping classes')
                  //get nextButton pos class
                  this.swapPosClasses('p' + (nextPosObject.number - 1), nextPosObject.class)
                  nextButton.classList.replace(nextPosObject.class, 'p' + (nextPosObject.number - 1))
                  // update nextbuttonPos
                  nextPosObject = this.getPosAndClass(nextButton.classList)
                  n++;
                }
                // update "next Button" href
                const getNextId = this.shadowRoot.querySelector(".p" + (nextPosObject.number + 1))
                nextButton.href = '#v' + getNextId.getAttribute('id').slice(1)
              } 

              break;
          }
          default:
          return
      }
  }

  getPosAndClass(classList){
    let posObject = {} // posObject.class posObject.number
    classList.forEach((className) => {
      if(className[0] === "p"){ 
        posObject.class = className
        posObject.number = Number(className.substring(1))
      }
    });
    return posObject
  }


  swapPosClasses(currentClass, newClass){
    const posDiv = this.shadowRoot.querySelector('.' + currentClass)
    posDiv.classList.replace(currentClass, newClass);
  }


  scrollToClick(e){
    e.preventDefault()
    const aTag = e.target.closest('a')
    const hrefParts = aTag.href.split('#')
    const vidId = hrefParts[1]
    document.getElementById(vidId).scrollIntoView();
  }

  // POSITION EQUATION: n * 55 - 50 // .p1 { top: 5px; } etc...
  // .track-btn - scale: 1.25; // animate size when moving 


  static styles = css`

    #the-tracker{
      background-color: var(--tracker-background);
    }

 
    #track-vids-box {
      position: relative;
    }

    .t-vid {
      border: solid 1px var(--grey-color-600);
      border-radius: 5px;
      background-color: var(--grey-color-800);
      left: .3em; 
      right: .3em;
      height: 50px;
      position: absolute;
      text-decoration: none;
      transition: all 0.8s ease;
    }

    .t-done-box { 
        width: 50px; 
        height: 50px; 
        float: left;
        display: flex; align-items: center; justify-content: center; 
    }
    
    .t-target {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: var(--grey-color-900);
        border-radius: 500px;
        width: 30px; 
        height: 30px;
        z-index: 20;
    }
    
    .t-done-box img { 
        opacity: 0;
        width: 10px;
        z-index: 100;
        transition: all 300ms ease-in;
    }
    .t-ticked img { width: 40px; height: 40px; opacity: 1; }

    .p1 { top: 5px; }
    .p2 { top: 60px; }
    .p3 { top: 115px; }
    .p4 { top: 170px; }
    .p5 { top: 225px; }

    .p6 { top: 280px; }
    .p7 { top: 335px; }
    .p8 { top: 390px; }
    .p9 { top: 445px; }
    .p10 { top: 500px; }

    .p11 { top: 555px; }
    .p12 { top: 610px; }
    .p13 { top: 665px; }
    .p14 { top: 720px; }
    .p15 { top: 775px; }

    .p16 { top: 830px; }
    .p17 { top: 885px; }
    .p18 { top: 940px; }
    .p19 { top: 995px; }
    .p20 { top: 1050px; }

    .p21 { top: 1105px; }
    .p22 { top: 1160px; }
    .p23 { top: 1215px; }
    .p24 { top: 1270px; }
    .p25 { top: 1325px; }

    .p26 { top: 1380px; }
    .p27 { top: 1435px; }
    .p28 { top: 1490px; }
    .p29 { top: 1545px; }
    .p30 { top: 1600px; }

    .p31 { top: 1655px; }
    .p32 { top: 1710px; }
    .p33 { top: 1765px; }
    .p34 { top: 1820px; }
    .p35 { top: 1875px; }

    .p36 { top: 1930px; }
    .p37 { top: 1985px; }
    .p38 { top: 2040px; }
    .p39 { top: 2095px; }
    .p40 { top: 2150px; }

    .track-btn {
        margin-top: 6px;
        position: absolute;
        left: 1.5em; 
        right: 1.5em;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        scale: 1;
        z-index: 50;
        transition: all 0.8s ease-in-out;
    }

    .move-btn {
        scale: 1.1;
    }
    


    .t-title {
        margin: 13px 3px 13px 0;
        font-size: 18px;
        white-space: nowrap;  
        overflow: hidden;       
        text-overflow: ellipsis;
        color: var(--text-color);
    }

    .btn {
      background-color: var(--action-color);
      border-radius: 500px;
      padding: 0.5em;
      color: white;
      font-family: 'Trebuchet MS', 'Gill Sans', Calibri, 'Gill Sans MT', sans-serif;
      outline: none;
      border:none; 
      text-decoration: none;
    }

    #vid-error {
      padding: 5em 1em;
    }

  `

  render(){    
    return html`

      <div id="the-tracker">
        <div id="track-vids-box">
          <a id="next-btn" href="#v-vid01" @click="${this.scrollToClick}" class="btn track-btn p1">Next >></a>
          <!-- insert links here -->
        </div>
      </div>
  
    `
  }
})
